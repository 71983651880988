import * as React from 'react';
import styles from './header.module.css';

interface Props {
  children: React.ReactNode;
}

function Header({ children }: Props) {
  return (
    <header className={styles.container}>
      <div className={styles.wrapper}>
        <div className="inner">
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </header>
  );
}

export default Header;
